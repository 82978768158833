import React, { useRef, useState, useEffect, useMemo } from 'react'
import { Formik } from 'formik'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import Box from '@mui/material/Box'

import DocumentPreviewContent from 'components/digital_mailbox/DocumentPreview'

import useDocumentInboundItem from 'hooks/digital_mail/useDocumentInboundItem'
import useDataList from 'hooks/useDataList'
import useNotification from 'hooks/context/useNotification'
import useUsers from 'hooks/users/useUsers'
import useInstance from 'hooks/instance/useInstance'
import useConfiguration from 'hooks/useConfiguration'

import isEmptyObject from 'helpers/node/isEmptyObject'
import {
  DIGITAL_MAIL_METADATA_INSTANCE_COLUMNS,
  MODULE_NAMES,
} from 'utils/constants'
import useAuth from 'hooks/useAuth'

function DocumentPreviewBody() {
  const formikRef = useRef(null)
  const { user } = useAuth()
  const [inboundId, setInboundId] = useState(undefined)
  const { setError, setBasicNotification } = useNotification()
  const { documentId } = useParams()
  const {
    documentInboundItem: document,
    getInboundId,
    updateInboundItem,
    mutate,
  } = useDocumentInboundItem(inboundId)
  const { getUserByDisplayName } = useUsers()
  const { getDigitalMailDisplayDocument, showDigitalMailDetailsItem } =
    useInstance()
  const { currentModule } = useConfiguration()

  const fieldDefinitions = useMemo(() => {
    const fd = currentModule?.configurations?.find(
      (config) => config.configuration_type === 'field_definitions'
    )
    const fieldSets = fd?.fieldsets || []
    let extraFields = {}

    fieldSets.forEach((fieldSet) => {
      extraFields = {
        ...extraFields,
        ...fieldSet.fields,
      }
    })

    return extraFields
  }, [currentModule])

  useEffect(() => {
    getInboundId(documentId).then((id) => setInboundId(id))
  }, [documentId])

  // this affects what is displayed in Digital Mail Preview
  const displayDocument = useMemo(
    () => getDigitalMailDisplayDocument(document),
    [document, getDigitalMailDisplayDocument, currentModule]
  )

  const {
    columns,
    dataListProps: { getDataDisplay },
  } = useDataList({
    baseRowData: [displayDocument],
    presetName: 'digitalMailbox',
    moduleName: MODULE_NAMES.DIGITAL_MAILBOX,
  })

  const validationSchema = useMemo(() => {
    const schema = {}
    const metaCols = DIGITAL_MAIL_METADATA_INSTANCE_COLUMNS[user.instanceId]

    if (Array.isArray(metaCols)) {
      metaCols.forEach((col) => {
        if (col.validate) {
          schema[col.id] = col.validate
        }
      })
    }

    return Yup.object().shape(schema)
  }, [user])

  const initialValues = useMemo(() => {
    const _initialValues = {
      category: getDataDisplay(displayDocument, 'category'),
      type: getDataDisplay(displayDocument, 'type'),
      status: getDataDisplay(displayDocument, 'status'),
      account_number: getDataDisplay(displayDocument, 'account_number'),
      assigned_to: getDataDisplay(displayDocument, 'assigned_to'),
      Drawer: getDataDisplay(displayDocument, 'Drawer'),
      FolderNumber: getDataDisplay(displayDocument, 'FolderNumber'),
      FileNumber: getDataDisplay(displayDocument, 'FileNumber'),
      DocumentType: getDataDisplay(displayDocument, 'DocumentType'),
      PageDescription: getDataDisplay(displayDocument, 'PageDescription'),
    }

    const metadataCols = Object.values(
      DIGITAL_MAIL_METADATA_INSTANCE_COLUMNS
    ).flatMap((meta) => meta.filter((col) => col.editable).map((col) => col.id))

    metadataCols.forEach((id) => {
      _initialValues[id] = getDataDisplay(displayDocument, id)
    })

    return _initialValues
  }, [displayDocument, getDataDisplay])

  const handleSubmit = async (values, { setStatus }) => {
    const submittedValues = {}

    if (!values.assigned_to) {
      values.assigned_to = 'Unassigned'
    }

    await Promise.all(
      Object.keys(values).map(async (key) => {
        const touched = initialValues[key] !== values[key]

        if (!showDigitalMailDetailsItem({ values, id: key, document })) {
          const fieldKey = `_.metadata.${key}`
          const fieldDefinition = fieldDefinitions[fieldKey]

          if (fieldDefinition) {
            submittedValues[fieldKey] = null
          } else {
            submittedValues[key] = null
          }
          return
        }

        if (touched) {
          const fieldKey = `_.metadata.${key}`
          const fieldDefinition = fieldDefinitions[fieldKey]
          if (fieldDefinition) {
            submittedValues[fieldKey] = values[key]
          } else if (key === 'assigned_to') {
            let user = { id: null }

            if (values[key] !== 'Unassigned') {
              user = await getUserByDisplayName(values[key])
            }

            if (user) {
              submittedValues['assigned_to_user_id'] = user.id
            }
          } else if (key === 'account_number') {
            submittedValues['account_id'] = values[key] || null
          } else if (key === 'member_id') {
            submittedValues['_.metadata.MemberID'] = values[key]
          } else {
            submittedValues[key] = values[key]
          }
        }
      })
    )

    try {
      if (isEmptyObject(submittedValues)) throw new Error('No changes made')

      await updateInboundItem(submittedValues, true)
      setStatus({ edited: false })
      setBasicNotification('Document updated successfully.')
      mutate()
    } catch (err) {
      setError(err.response?.data?.display_message || 'Error updating document')
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      initialStatus={{ edited: false }}
      enableReinitialize
      validationSchema={validationSchema}
    >
      <Box>
        {inboundId && (
          <DocumentPreviewContent
            columns={columns}
            getDataDisplay={getDataDisplay}
            displayDocument={displayDocument}
            inboundId={inboundId}
          />
        )}
      </Box>
    </Formik>
  )
}

function DocumentPreview() {
  const [mounted, setMounted] = useState(false)
  const { currentModule } = useConfiguration()

  useEffect(() => {
    setMounted(Boolean(currentModule))
  }, [currentModule])

  if (!mounted) return <div></div>

  return <DocumentPreviewBody />
}

export default DocumentPreview
